import React from 'react';
import { Table } from 'antd';
import './table.scss';


//https://ant.design/components/table#table
const TableComponent = ({
    columns = [],
    dataSource = [],
    showSorterTooltip = false,
    scroll = {},
    pagination = {},
    className = '',
    rowClassName = '',
    onClickEvent = () => {},
    onChangeEvent = () => {},
    onRow = () => {},
    summary = () => {},
    style = {},
    loading = false,
    expandable = {},
    rowKey = 'key',
    elipsis = true,
    bordered = true,
    components = '',
    size = 'large',
    locale = ''
}) => {
    return (
        <Table
            components={components}
            columns={columns}
            dataSource={dataSource}
            showSorterTooltip={showSorterTooltip}
            scroll={scroll}
            className={className}
            onClick={onClickEvent}
            onChange={onChangeEvent}
            style={style}
            onRow={onRow}
            summary={summary}
            loading={loading}
            pagination={pagination}
            expandable={expandable}
            rowClassName={rowClassName}
            rowKey={rowKey}
            elipsis={elipsis}
            bordered={bordered}
            size={size}
            locale={locale}
        />
    );
};

export default TableComponent;
