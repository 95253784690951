import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { Content } from "antd/es/layout/layout";
import { Permissions } from "@liveline/ll_node_common";
import "./style.scss";
import Header from "../../layout/header";
import { Layout, LoadingOverlay } from "../../components/shared";
import Navbar from "../../layout/navbar";
import Footer from "../../layout/footer";
import Login from "./login/index";
import logo from "../../assets/dark-theme/icons/ll_full_logo_white_text_xparent_bkgd.svg";
import { SharedObjectProvider } from "../../components/shared/ContextProvider";
import { AbilityContext } from "../../components/shared/Can";

const PrivateRoutes = () => {
    const isAuthenticated = useSelector((x) => Boolean(x?.auth?.user));
    const ability = useSelector((x) =>
        Permissions.defineAbility(x?.auth?.user?.permissions)
    );
    const [darkTheme, setdarkTheme] = useState("darkTheme");
    const [visibleSideMenu, setVisibleSideMenu] = useState(true); // True = Nav is collapsed (closed)-
    const [mobileCollapse, setMobileCollpse] = useState(false);
    const [mobile, setMobile] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true);
    const location = useLocation();

    // Controls the display of the footer component-
    const [showFooter, setShowFooter] = useState(true);

    const onCollapseMenu = () => {
        // When on the dashboard and its the initial load; keep nav menu closed-
        if (location.pathname === "/" && initialLoad) {
            setInitialLoad(false);
            return;
        }
        if (!mobile) {
            setVisibleSideMenu(!visibleSideMenu);
        } else {
            setMobileCollpse(!mobileCollapse);
        }
    };

    const resizeFun = () => {
        const width = window.innerWidth;
        const isDefault = localStorage.getItem("isDefault");
        if (width < 1024) {
            if (isDefault !== "false") {
                setVisibleSideMenu(true); // True = Nav is collapsed (closed)-
                setMobile(true);
                setMobileCollpse(true);
            }
        } else {
            setMobileCollpse(false);
            setMobile(false);
            setVisibleSideMenu(true); // True = Nav is collapsed (closed)-
        }
    };

    const onToggle = () => {
        localStorage.setItem("isDefault", "false");
        setMobileCollpse(!mobileCollapse);
    };

    // Reset the initial load and side menu on logging out-
    const setLogout= () => {
        setInitialLoad(true);
        setVisibleSideMenu(true);
    };

    useEffect(() => {
        localStorage.setItem("isDefault", "true");
        window.addEventListener("resize", resizeFun);
        resizeFun();
        return () => {
            window.removeEventListener("resize", resizeFun);
        };
    }, []);

    useEffect(() => {
        if (location.pathname === "/live_historical_data/monitor_live_data") {
            document.body.classList.add("no-scroll");
        }
        if (
            location.pathname === "/live_historical_data/advanced_controllers"
        ) {
            setShowFooter(false); // Hide the footer-
        } else {
            setShowFooter(true);
        }

        return () => {
            document.body.classList.remove("no-scroll");
        };
    }, [location]);

    // When the side Navbar is open/closed - set the localStorage value & create a new window event-
    useEffect(() => {
        localStorage.setItem("visibleSideMenu", visibleSideMenu.toString());
        window.dispatchEvent(new Event("visibleSideMenuChange"));
    }, [visibleSideMenu]);

    return isAuthenticated ? (
        <div className={darkTheme}>
            <AbilityContext.Provider value={ability}>
                <Layout>
                    <LoadingOverlay>
                        <Navbar
                            onCollapseMenu={onCollapseMenu}
                            visibleSideMenu={visibleSideMenu}
                            mobile={mobile}
                            mobileCollapse={mobileCollapse}
                        />
                        <Layout className="site-layout">
                            <SharedObjectProvider>
                                <Header
                                    mobile={mobile}
                                    onToggle={onToggle}
                                    setLogout={setLogout} // Reset the Initial Load and Side Nav Menu when logging out-
                                />
                                <Content
                                    style={
                                        showFooter
                                            ? { height: "100%", padding: 10 }
                                            : { maxHeight: "100vh" }
                                    }
                                >
                                    <Outlet />
                                </Content>
                                {showFooter ? (
                                    <Footer
                                        onToggle={onToggle}
                                        mobileCollapse={mobileCollapse}
                                    />
                                ) : null}
                            </SharedObjectProvider>
                        </Layout>
                    </LoadingOverlay>
                </Layout>
            </AbilityContext.Provider>
        </div>
    ) : (
        <>
            <div className={darkTheme} style={{ height: "100%" }}>
                <div
                    className="site-layout"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        height: "100%",
                    }}
                >
                    <div className="logoView">
                        <img src={logo} alt="logo" className="logoIMG" />
                    </div>
                    <Login />
                </div>
            </div>
        </>
    );
};

export default PrivateRoutes;
