import { createAsyncThunk, createSlice, PayloadAction, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { history } from '../../utils/history';
import { createUserObj, transformUserObj } from '../../utils/commonFunctions';
import { FacilityUserRole } from '../../services/apiTypes';
export interface User {
    idToken?: string;
    refreshToken?: string;
    accessToken?: string;
    customerApiBase?: string;
    apiURL?: string;
    permissions?: FacilityUserRole[];
    [key: string]: any;
}

export interface AuthState {
    user: User | null;
    error: string | null;
    loading: boolean;
    authChallengeData: any | null;
}

interface LoginCredentials {
    username: string;
    password: string;
}

interface ForgotPasswordCredentials {
    username: string;
}

interface RecoverPasswordCredentials {
    username: string;
    password: string;
    code: string;
}

interface ChallengeCredentials {
    username: string;
    password: string;
    sessionToken: string;
}

interface RefreshTokenParams {
    userInfo: User;
}


function createInitialState(): AuthState {
    const user = localStorage.getItem('user') || "";
    return {
        // initialize state from local storage to enable user to stay logged in
        user: user ? JSON.parse(user) : null,
        error: null,
        loading: false,
        authChallengeData: null
    };
}

function createReducers() {
    return {
        logout,
    };

    function logout(state: AuthState): void {
        state.user = null;
        localStorage.removeItem('user');
        history?.navigate('/');
    }
}

const name = 'auth';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_AUTH_URL}`;

    return {
        login: login(),
        forgotPassword: forgotPassword(),
        recoverPassword: recoverPassword(),
        refreshToken: refreshToken(),
        respondToChallenge: respondToChallenge()
    };

    function login() {
        return createAsyncThunk<User, LoginCredentials>(`${name}/login`, async ({ username, password }) => {
            const form = new FormData();
            form.append('username', username);
            form.append('password', password);
            const body = new URLSearchParams(form as any);
            try {
                const response = await fetch(baseUrl + '/login', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: body,
                });
                const data = await response.json();
                if (!data?.idToken && !(data?.ChallengeName === 'NEW_PASSWORD_REQUIRED')) {
                    throw Error(data.message);
                }
                return data;
            } catch (e) {
                throw e;
            }
        });
    }

    function forgotPassword() {
        return createAsyncThunk<any, ForgotPasswordCredentials>(`${name}/forgot-password`, async ({ username }) => {
            const form = new FormData();
            form.append('username', username);
            const body = new URLSearchParams(form as any);
            const response = await fetch(baseUrl + '/forgot-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: body,
            });
            const data = await response.json();
            if (response.status === 401) {
                throw Error(data.message);
            }
            return data;
        });
    }

    function recoverPassword() {
        return createAsyncThunk<any, RecoverPasswordCredentials>(`${name}/recover-password`, async ({ username, password, code }) => {
            const form = new FormData();
            form.append('username', username);
            form.append('password', password);
            form.append('confirmationCode', code);
            const body = new URLSearchParams(form as any)
            const response = await fetch(baseUrl + '/recover-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: body,
            });
            const data = await response.json();
            if (response.status === 401) {
                throw Error(data.message);
            }
            return response;
        });
    }

    function refreshToken() {
        return createAsyncThunk<User, RefreshTokenParams>(`${name}/refresh-token`, async ({ userInfo }) => {
            return userInfo;
        });
    }

    function respondToChallenge() {
        return createAsyncThunk<User, ChallengeCredentials>(`${name}/repond-to-challenge`, async ({ username, password, sessionToken }) => {
            const form = new FormData();
            form.append('username', username);
            form.append('password', password);
            form.append('sessionToken', sessionToken);
            const body = new URLSearchParams(form as any)
            const response = await fetch(baseUrl + '/confirm-new-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: body,
            });
            const data = await response.json();
            if (data?.$metadata?.httpStatusCode === 200) {
                if (!data?.AuthenticationResult?.AccessToken) {
                    throw Error('No Access Token - ', data.message);
                } else {
                    let userObj = createUserObj({
                        ...data,
                        refreshToken: data?.AuthenticationResult?.AccessToken
                    });
                    localStorage.setItem('user', JSON.stringify(userObj));
                    return userObj;
                }
            } else {
                throw Error(data.message);
            }
        });
    }
}
//sets state
function createExtraReducers() {
    return (builder: ActionReducerMapBuilder<AuthState>) => {
        login();
        resetPassword();
        recoverPassword();
        refreshToken();
        respondToChallenge();

        function login() {
            const { pending, fulfilled, rejected } = extraActions.login;
            builder
                .addCase(pending, (state: AuthState) => {
                    state.error = null;
                    state.loading = true;
                })
                .addCase(fulfilled, (state: AuthState, action: PayloadAction<User>) => {
                    let authResponse = action.payload;
                    // store user details and basic auth data in local storage to keep user logged in between page refreshes
                    // use external auth if outside cps vpn

                    if (authResponse?.idToken) {
                        const user = authResponse;
                        const transformedUserObj = transformUserObj(user);
                        localStorage.setItem('user', JSON.stringify(transformedUserObj));
                        state.user = transformedUserObj;

                        state.loading = false;
                        // get return url from location state or default to home page
                        const { to } = history.location.state || { to: { pathname: '/' } };
                        history.navigate(to);
                    } else if (authResponse?.Session) {
                        state.authChallengeData = authResponse;
                        state.error = "Please create a new password. Passwords must be at least 12 characters long and include upper and lowercase letters, numbers, and special characters."
                    } else {
                        state.error = authResponse.message;
                        state.loading = false;
                    }
                })
                .addCase(rejected, (state: AuthState, action) => {
                    state.error = action.error?.message ?? 'An error occurred during login';
                    state.loading = false;
                });
        }

        function refreshToken() {
            const { pending, fulfilled, rejected } = extraActions.refreshToken;
            builder
                .addCase(pending, (state: AuthState) => {
                    state.error = null;
                    state.loading = true;
                })
                .addCase(fulfilled, (state: AuthState, action: PayloadAction<User>) => {
                    const response = action.payload;
                    const user = transformUserObj(response);
                    localStorage.setItem('user', JSON.stringify(user));
                    state.user = user;
                    state.loading = false;
                })
                .addCase(rejected, (state: AuthState, action) => {
                    state.error = action.error?.message ?? 'An error occurred refreshing the token';
                    state.loading = false;
                });
        }

        function resetPassword() {
            const { pending, fulfilled, rejected } = extraActions.forgotPassword;
            builder
                .addCase(pending, (state: AuthState) => {
                    state.error = null;
                    state.loading = true;
                })
                .addCase(fulfilled, (state: AuthState, action: PayloadAction<any>) => {
                    // store user details and basic auth data in local storage to keep user logged in between page refreshes
                    state.loading = false;
                })
                .addCase(rejected, (state: AuthState, action) => {
                    state.error = action.error?.message ?? 'An error occurred resetting password';
                    state.loading = false;
                });
        }

        function recoverPassword() {
            const { pending, fulfilled, rejected } = extraActions.recoverPassword;
            builder
                .addCase(pending, (state: AuthState) => {
                    state.error = null;
                    state.loading = true;
                })
                .addCase(fulfilled, (state: AuthState, action: PayloadAction<any>) => {
                    state.loading = false;
                    // store user details and basic auth data in local storage to keep user logged in between page refreshes
                    const { to } = history.location.state || { to: { pathname: '/' } };
                    history.navigate(to);
                })
                .addCase(rejected, (state: AuthState, action) => {
                    state.error = action?.error?.message ?? 'An error occurred recovering password';
                    state.loading = false;
                });
        }

        function respondToChallenge() {
            const { pending, fulfilled, rejected } = extraActions.respondToChallenge;
            builder
                .addCase(pending, (state: AuthState) => {
                    state.error = null;
                    state.loading = true;
                })
                .addCase(fulfilled, (state: AuthState, action: PayloadAction<User>) => {
                    const user = action.payload;
                    const transformedUserObj = transformUserObj(user);

                    localStorage.setItem('user', JSON.stringify(transformedUserObj));
                    state.user = user;
                    state.authChallengeData = null;

                    state.loading = false;
                    // get return url from location state or default to home page
                    const { to } = history.location.state || { to: { pathname: '/' } };
                    history.navigate(to);

                })
                .addCase(rejected, (state: AuthState, action) => {
                    state.error = action?.error?.message ?? 'An error occurred responding to challenge';
                    state.loading = false;
                });
        }
    };
}


export const authActions = { ...slice.actions, ...extraActions };
export const authReducer = slice.reducer;