import { useLocation, useNavigate } from "react-router-dom";
import "./app.scss";
import ContentRoutes from "./layout/routes";
import { history } from "./utils/history";

import { ErrorBoundary } from "react-error-boundary"; // Error catch library-
import ErrorBoundaryFallback from "./layout/private-routes/error-boundary/index"; //This is the fallback component-

// custom hook-
import useSystemLanguage from "./hooks/useSystemLanguage";

function App() {
    const errorHandler = (error: any, errorInfo: any) => {
        console.log("Logging: ", error, errorInfo);
    };

    history.location = useLocation();
    history.navigate = useNavigate();

    useSystemLanguage();

    return (
        <ErrorBoundary
            FallbackComponent={ErrorBoundaryFallback}
            onError={errorHandler}
        >
            <ContentRoutes />
        </ErrorBoundary>
    );
}

export default App;
