import { combineReducers } from "redux";
import { authReducer, AuthState } from "./authReducer";

// Reducer-
import cellReducer from "./cellReducer";
import facilityReducer from "./facilityReducer";
import { userSelectionReducer, UserContextSelection } from "./userSelectionReducer";
import { facilityListReducer, FacilityListState } from "./facilityListReducer";
import { cellListReducer, CellListState } from "./cellListReducer";
import cellServicesReducer from "./cellServicesReducer";
import { plotReducer } from "./plotReducer";

export interface RootState {
    auth: AuthState;
    userSelection: UserContextSelection;
    facility: any;
    cell: any;
    facilityList: FacilityListState;
    cellList: CellListState;
    cellServices: any;
    plots: any;
}

const rootReducer = combineReducers<RootState>({
    auth: authReducer,
    userSelection: userSelectionReducer,
    facility: facilityReducer,
    cell: cellReducer,
    facilityList: facilityListReducer,
    cellList: cellListReducer,
    cellServices: cellServicesReducer,
    plots: plotReducer,
});

export default rootReducer;
