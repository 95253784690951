import React from 'react';
import { Button, Form } from 'antd';
import './formFooter.scss';

interface FormFooterProps {
    name: string;
    onCancel: () => void;
    className?: string;
    disabled?: boolean;
    okText?: string;
}

const FormFooterComponent = ({ name, onCancel, className, disabled, okText='Ok' }: FormFooterProps) => {
    return (
        <Form.Item name={name} className={className}>
            <div>
                <Button onClick={onCancel} className='cancelFooterBtn'>Cancel</Button>
                <Button type='primary' htmlType='submit' className='okFooterBtn' disabled={disabled}>
                    {okText}
                </Button>
            </div>
        </Form.Item>
    );
};

export default FormFooterComponent;
