import React, { lazy } from "react";

const Cells = lazy(() => import("./cells"));
const Products = lazy(() => import("./products"));
const Facilities = lazy(() => import("./facilities"));
const Equipment = lazy(() => import("./equipment"));
const Users = lazy(() => import("./users"));

const AdminRoute = () => {
  return [
    {
      key: "cells",
      path: "/admgeneral_adminin/cells",
      component: <Cells />,
    },
    {
      key: "products",
      path: "/general_admin/products",
      component: <Products />,
    },
    {
      key: "facilities",
      path: "/general_admin/facilities",
      component: <Facilities />,
    },
    {
      key: "equipment",
      path: "/general_admin/equipment",
      component: <Equipment />,
    },
    {
      key: "users",
      path: "/general_admin/users",
      component: <Users />,
    }
  ];
};

export default AdminRoute;
