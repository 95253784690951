import { Modal } from 'antd';
import './confirm.scss';

const { confirm } = Modal;

const Confirm = ({ title, icon, content, onOk = () => {}, onCancel = () => {}, cancelText = "Cancel", okText = "Ok", className }) => {
    confirm({
        title,
        icon,
        content,
        onOk() {
            onOk();
        },
        closable: true,
        onCancel() {
            onCancel();
        },
        cancelText,
        okText,
        className: 'confirmation' + ` ${className}`,
    });
};

export { Confirm };
