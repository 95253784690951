import { createSlice, createAsyncThunk, Dispatch, ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { APIClient } from "../../services/clientAPI";
import { userSelectionActions } from "./userSelectionReducer";
import { User } from "./authReducer";
const name = "facilityList";

export interface Facility {
    id: number; //customer facilityId
    text: string;
    facilityId?: string //livelineFacilityId
}

export interface FacilityListState {
    data: Facility[];
    loading: boolean;
}

function createInitialState(): FacilityListState {
    const stored = localStorage.getItem(name) || "[]";
    const data = JSON.parse(stored);
    return {
        data,
        loading: false,
    };
}

const initLivelineFacilitySelection = (dispatch: Dispatch, selectedFacility: any) => {
    dispatch(userSelectionActions.setUserSelection(selectedFacility));
}

async function facilityFetch(apiUrl: string, thunkAPI: any) {
    if (apiUrl) {    
        const client = new APIClient(apiUrl, thunkAPI.dispatch);

        const response = await client.getAllFacilities();
        let facilities = response && response.data && response.data.length
            ? response.data.map((facility: any) => {
                return {
                    id: facility?.id,
                    text: facility?.facilityName,
                    value: facility?.id,
                    label: facility?.facilityName,
                    facilityId: facility?.livelineFacilityId
                };
            })
            : [];

        const stored = localStorage.getItem('user') || "{}"
        const user: User = JSON.parse(stored);

        if (user?.permissions) {
            facilities = facilities.filter(function ({ facilityId }) {
                return (user?.permissions || []).findIndex(p => p.facilityId === facilityId) > -1
            })
        }

        localStorage.setItem(name, JSON.stringify(facilities));

        const storedSelection = localStorage.getItem('userSelectionInfo') || "{}";
        const userSelection = JSON.parse(storedSelection);

        if (facilities.length && !userSelection?.facility?.livelineId) {
            const {
                id,
                text,
                facilityId,
            } = facilities[0];
    
            const facility = {
                id,
                text,
                livelineId: facilityId,
            };
    
            initLivelineFacilitySelection(thunkAPI.dispatch, { facility });
        }

        return facilities;
    }

    return [];
}



export const fetchFacilitiesList = createAsyncThunk(`${name}/fetch`, facilityFetch)

export const slice = createSlice({
    name,
    initialState: createInitialState(),
    reducers: {
        setFacilityList: (state, action) => {
            // Update Facility List
            return action.payload;
        },
        logout: (state) => {
            state.data = [];
            localStorage.removeItem(name);
        },
    },
    extraReducers: (builder: ActionReducerMapBuilder<FacilityListState>) => {
        builder
            .addCase(fetchFacilitiesList.fulfilled, (state, action) => {
                return {
                    loading: false,
                    data: action.payload || [],
                };
            })
            .addCase(fetchFacilitiesList.pending, (state) => ({
                loading: true,
                data: state.data,
            }))
            .addCase(fetchFacilitiesList.rejected, () => ({
                loading: false,
                data: [],
            }))
    },
});

// Action creator
export const facilityListActions = slice.actions;

export const facilityListReducer = slice.reducer;
