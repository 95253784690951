import React, { useEffect } from 'react';
import FacilityCell from './FacilityCell';
import { useDispatch, useSelector } from 'react-redux';
import { userSelectionActions } from '../../../state/features/userSelectionReducer';
import { RootState } from '../../../state/features/reducers';

interface FacilityCellContainerProps {
    showCell?: boolean;
    onChangeEvent: (facilityId?: string | number, cellId?: string | number) => void;
    currentMenu?: any;
    showInfo?: boolean;
}

const FacilityCellContainer = ({
    showCell = false,
    onChangeEvent,
    currentMenu,
    showInfo = true
}: FacilityCellContainerProps) => {
    const dispatch = useDispatch();

    const userSelection = useSelector((x: RootState) => x?.userSelection);
    const cellList = useSelector((state: RootState) => state?.cellList);

    useEffect(() => {
        onChangeEvent(userSelection?.facility?.id, userSelection?.cell?.id);
    }, [userSelection]);

    // Cell Handler: sets the state of the cell based on user selection
    const cellHandler = (cellName: string) => {
        const cell = cellList?.data?.find(c => c.name === cellName);

        if (cell?.id) {
            dispatch(userSelectionActions.setUserSelection({ cell }))
        }
    }

    return (
        <FacilityCell
            cellHandler={cellHandler}
            selectedCell={userSelection?.cell}
            cellList={cellList}
            currentMenu={currentMenu}
            showCell={showCell}
            showInfo={showInfo}
        />
    );
};

export default FacilityCellContainer;
