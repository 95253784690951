// Create a custom hook to manage the client instance
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { APIClient } from './clientAPI'
import { useDispatch } from 'react-redux';
import { RootState } from '../state/features/reducers';

const useApiClient = (base?: boolean) => {
  const dispatch = useDispatch();
  let api : string = useSelector<RootState>(x => base ? x?.auth?.user?.customerApiBase : x?.auth?.user?.apiURL) as string;
  const token = useSelector<RootState>(x => x?.auth.user?.accessToken);

  // Ensure the client is created only once using useEffect with an empty dependency array
  const client = useMemo(() => {
    if (api) {
      return new APIClient(api, dispatch);
    }
  }, [api, token]);

  return client;
};

export default useApiClient;
