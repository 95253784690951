import React from 'react';

import { ConfigProvider } from 'antd';

export interface ConfigProviderComponentProps {
    className?: string;
    theme?: any;
    children: React.ReactNode;
}

const ConfigProviderComponent = ({ className = '', theme, ...props }: ConfigProviderComponentProps) => {
    return (
        <ConfigProvider theme={theme}>
            {props.children}
        </ConfigProvider>
    );
};

export default ConfigProviderComponent;
