import axios, { AxiosInstance, AxiosResponse, CancelTokenSource }  from "axios";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { Dispatch } from "redux";
import { Notification } from "../components/shared";

import { authActions } from "../state/features/authReducer";
import { createUserObj } from "../utils/commonFunctions";
import useAppLogout from "../hooks/useAppLogout";
import { User, Role, LivelineFacility, UserFacilityRole, Signup, CriticalTagStatsResponse, FacilityUserRole, FaclityCell, PaginationMeta } from "./apiTypes";

// eslint-disable-next-line
const logout = useAppLogout();

export class APIClient {
    logOut = () => {
        logout(this.dispatch);
    };

    public instance: AxiosInstance;
    dispatch: any;
    cancelTokenSource: CancelTokenSource;
    refreshTokenInitial: string;

    checkSessionExpiry = (token: string) => {
        try {
            let res = false;
            if (token) {
                const decodedPayload: JwtPayload = jwtDecode(token);
                if (decodedPayload?.exp) {
                    const expirationTime = decodedPayload.exp;
                    const currentTime = Math.floor(Date.now() / 1000);
                    if (currentTime > expirationTime) {
                        res = true;
                    } else {
                        //console.log("not expired");
                    }
                } else {
                    res = true;
                }
            } else {
                console.log("no token");
                res = true;
            }
            return res;
        } catch (ex) {
            console.error("Exception in checkSessionExpiry - ", ex);
            return true;
        }
    };

    baseUrl = `${process.env.REACT_APP_AUTH_URL}`;

    refreshAccessToken = async (refreshToken: string) => {
        const form = new FormData();
        form.append("token", refreshToken);
        const body = new URLSearchParams(form as any);
        try {
            const response = await fetch(this.baseUrl + "/refresh-token", {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: body,
            });
            const data = await response.json();
            //console.log(data);
            if (data?.$metadata?.httpStatusCode === 200) {
                if (!data?.AuthenticationResult?.AccessToken) {
                    throw Error("No Access Token - ", data.message);
                } else {
                    let userObj = createUserObj({
                        ...data,
                        refreshToken: this.refreshTokenInitial,
                    });
                    return userObj;
                }
            } else {
                throw Error("Failed to fetch New Token!");
            }
        } catch (e) {
            throw e;
        }
    };

    async refreshToken() {
        try {
            const refreshToken = this.refreshTokenInitial;
            if (!refreshToken) {
                this.logOut();
                this.cancelTokenSource.cancel(
                    "Request canceled - Token Expired No refresh token available."
                );
            }
            // Perform the token refresh operation
            const newAccessTokenObj = await this.refreshAccessToken(
                refreshToken
            );
            const newAccessToken = newAccessTokenObj?.accessToken;
            this.dispatch(
                authActions.refreshToken({ userInfo: newAccessTokenObj })
            );
            return newAccessToken;
        } catch (error) {
            this.logOut();
            this.cancelTokenSource.cancel(`Request canceled -  ${error}`);
        }
    }

    sleep(ms: number) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    getUserInfo = () => {
        const userObj = localStorage.getItem("user") ?? "";
        const userJson = JSON.parse(userObj);
        const accessToken = userJson?.accessToken;
        const refreshToken = userJson?.refreshToken;
        const idToken = userJson?.idToken;
        const expiresIn = userJson?.expiresIn;
        return { accessToken, refreshToken, idToken, expiresIn };
    };

    constructor(url: string, dispatch: Dispatch) {
        this.dispatch = dispatch;

        const userInfo = this.getUserInfo();

        this.refreshTokenInitial = userInfo?.refreshToken;

        // Create a CancelToken source
        this.cancelTokenSource = axios.CancelToken.source();

        this.instance = axios.create({
            baseURL: url,
            headers: {
                Authorization: `Bearer ${userInfo?.accessToken}`,
            },
            cancelToken: this.cancelTokenSource.token,
        });

        this.instance.interceptors.request.use(
            async (config) => {
                if (config?.headers?.Authorization) {
                    try {
                        const userInfo = this.getUserInfo(); // can fail if no user is set, prompting logout
                        const token = userInfo?.accessToken;
                        // Check if the token is expired
                        config.headers.Authorization = `Bearer ${token}`; //make sure it's using the latest
                        const isExpired = this.checkSessionExpiry(token);
                        if (isExpired) {
                            console.log("expired and attempting to refresh");
                            if (!userInfo?.expiresIn) {
                                // Refresh the token (if not already in progress)
                                const newAccessToken =
                                    await this.refreshToken();
                                config.headers.Authorization = `Bearer ${newAccessToken}`;
                                return config;
                            } else {
                                this.logOut();
                                this.cancelTokenSource.cancel(
                                    "Request canceled - Token Expired No Refresh Token!"
                                );
                                //throw err
                            }
                        }
                    } catch (error) {
                        console.log("something failed", error);
                        this.logOut();
                        this.cancelTokenSource.cancel(
                            `Request canceled - ${error}`
                        );
                        //throw err
                    }
                } else {
                    this.logOut();
                    this.cancelTokenSource.cancel(
                        "Request canceled - Authorization not found!"
                    );
                    //throw errr
                }
                return config;
            },
            (error) => {
                this.logOut();
                this.cancelTokenSource.cancel(`Error in interceptor - ${error}`);
            }
        );

        // this.instance.interceptors.response.use(function (response){
        //     return response
        // }, async function (error) {
        //     console.log(error.request);
        //     if (401 === error.request.status) {
        //         // handle error: inform user, go to login, etc
        //         console.log('401');
        //         await this.refreshToken();
        //     } else {
        //         return Promise.reject(error);
        //     }
        // })
    }

    handlePagination = (url: string, pageNum: number) => {
        if (pageNum > 1) {
            return `${url}?page_num=${pageNum}`;
        }

        return url;
    };

    catchError = (title: string, functionName: string, error: any) => {
        // console.log(`Exception in  ${functionName}:`, error);
        if (!axios.isCancel(error)) {
            Notification.FailedNotification(
                title,
                error?.response?.data?.message || error?.message
            );
            return {};
        }
    };

    getOrigin() {
        try {
            const url = new URL(this.instance.defaults.baseURL!);
            return url.origin;
        } catch (error) {
            console.log(error);
        }
        return this.instance.defaults.baseURL;
    }

    async getRules(cellId: number, productId: number) {
        try {
            const cell_id = Number(cellId);
            const product_id = Number(productId);
            const response = await this.instance(
                `/rule-configs/rules/cell/${cell_id}/product/${product_id}`
            );
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Rules Configuration", "getRules", error);
        }
    }

    async getServiceUrl(jobDescription: string, facilityId: number, cellId: number) {
        try {
            const job = String(jobDescription).toLowerCase();
            const facility = String(facilityId).toLowerCase();
            const cell = String(cellId).toLowerCase();
            const queryParams = { job, facility, cell };
            const response = await this.instance(`/service-url`, {
                params: queryParams,
            });
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Service URL", "getServiceUrl", error);
        }
    }

    async getRuleControllerAction(facilityId: number, cellId: number, action: string) {
        try {
            const response = await this.instance(
                `/rules-controller/facility/${facilityId}/cell/${cellId}/action/${action}`
            );
            return response?.data ?? {};
        } catch (error: any) {
            this.catchError(
                "Rules Controller",
                "getRuleControllerAction",
                error
            );
            return error?.status;
        }
    }

    async postRuleControllerAction(facilityId: number, cellId: number, reqBody: any) {
        try {
            const url = `/rules-controller/facility/${facilityId}/cell/${cellId}/action/updaterule`;
            const response = await this.instance.post(url, reqBody);
            return response?.data ?? {};
        } catch (error: any) {
            this.catchError(
                "Rules Controller",
                "postRuleControllerAction",
                error
            );
            return error?.status;
        }
    }

    async getLoadMatrices(facilityId: number, cellId: number) {
        try {
            const url = `/matrix-controller/facility/${facilityId}/cell/${cellId}/action/loadmatrices`;
            const response = await this.instance.get(url);
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Load Matrices", "getLoadMatrices", error);
        }
    }

    async getMatrixRefreshPart(facilityId: number, cellId: number) {
        try {
            const url = `/matrix-controller/facility/${facilityId}/cell/${cellId}/action/refreshpart`;
            const response = await this.instance.get(url);
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Service URL", "getServiceUrl", error);
        }
    }

    async getMatrixRepostDashboard(facilityId: number, cellId: number) {
        try {
            const url = `/matrix-controller/facility/${facilityId}/cell/${cellId}/action/repostdashboard`;
            const response = await this.instance.get(url);
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Service URL", "getServiceUrl", error);
        }
    }

    async getLoadRules(facilityId: number, cellId: number) {
        try {
            const url = `/rules-controller/facility/${facilityId}/cell/${cellId}/action/loadrules`;
            const response = await this.instance.get(url);
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Service URL", "getServiceUrl", error);
        }
    }

    async getRulesRefreshPart(facilityId: number, cellId: number) {
        try {
            const url = `/rules-controller/facility/${facilityId}/cell/${cellId}/action/refreshpart`;
            const response = await this.instance.get(url);
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Service URL", "getServiceUrl", error);
        }
    }

    async getRulesRepostDashboard(facilityId: number, cellId: number) {
        try {
            const url = `/rules-controller/facility/${facilityId}/cell/${cellId}/action/repostdashboard`;
            const response = await this.instance.get(url);
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Service URL", "getServiceUrl", error);
        }
    }

    async getImage(cellId: number, productId: number) {
        try {
            const response = await this.instance(
                `/product-images/cell/${cellId}/product/image/${productId}`
            );
            return response;
        } catch (error) {
            //his.catchError('Rules Configuration', 'getImage', error);
        }
    }

    async getAllFacilities() {
        try {
            const response = await this.instance.get<AxiosResponse<LivelineFacility[]>>(`/facilities/facilities`);
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Facilities", "getFacilities", error);
        }
    }

    async getFacilityCells(facility_id: number) {
        try {
            const facilityId = Number(facility_id);
            const response = await this.instance.get<{ data: FaclityCell[], meta: PaginationMeta}>(
                `/cells/cells/facility/${facilityId}`
            );
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Cells", "getCells", error);
        }
    }

    async getProductCellAssignedPart(part_id: number, cell_id: number, page_num: number, page_size: number) {
        try {
            const productId = Number(part_id);
            const cellId = Number(cell_id);
            const queryParams = { page_num, page_size };
            const response = await this.instance.get(
                `/products-assigned/assigned-parts/cell/${cellId}/products/${productId}`,
                { params: queryParams }
            );
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Products", "getProductCellAssignedPart", error);
        }
    }

    async getRecordPartAttribute(
        facility_line_id: number,
        part_id: number,
        attribute_id: number,
        facility_line_assigned_parts_id: number
    ) {
        try {
            const cell_id = Number(facility_line_id);
            const queryParams = {
                part_id,
                attribute_id,
                facility_line_assigned_parts_id,
            };
            const response = await this.instance.get(
                `/part-attributes/part-attributes/cellId/${cell_id}`,
                { params: queryParams }
            );
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Products", "getRecordPartAttribute", error);
        }
    }

    async getCellParts(cell_id: number, only_active: boolean, page_num: number) {
        try {
            const cellId = Number(cell_id);
            const queryParams = { only_active, page_num };
            const response = await this.instance.get(
                `/products-assigned/assigned-parts/cell/${cellId}/products`,
                { params: queryParams }
            );
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Parts", "getCellAssignedParts", error);
        }
    }

    async getFacilityProducts(facility_id: number) {
        try {
            const facilityId = Number(facility_id);
            const response = await this.instance.get(
                `/products/facilityId/${facilityId}`
            );
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Parts", "getFacilityProducts", error);
        }
    }

    async getEquipment(cell_id: number) {
        try {
            const cellId = Number(cell_id);
            const response = await this.instance.get(
                `/equipment/cell/${cellId}`
            );
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Equipment", "getEquipment", error);
        }
    }

    async getLDCPServices(cell_id: number) {
        try {
            const cellId = Number(cell_id);
            const response = await this.instance.get(
                `/equipment/cell/${cellId}/ldcp`
            );
            return response?.data ?? {};
        } catch (error) {
            console.error(error);
            //this.catchError("Equipment", "getLDCPServics", error);
        }
    }

    async getEquipmentTypes(cell_id: number) {
        try {
            const response = await this.instance.get(
                `/equipment/meta/equipment-types`
            );
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Equipment", "getEquipmentTypes", error);
        }
    }

    async updateEquipment(id: number, editEquipmentRequestBody: any) {
        try {
            const url = `/equipment/equipmentid?id=${id}`;
            const response = await this.instance.put(
                url,
                editEquipmentRequestBody
            );
            return response?.status;
        } catch (error) {
            this.catchError("Equipment", "updateEquipment", error);
        }
    }

    async refreshEquipment(cellId: number) {
        try {
            const url = `/equipment/cell/${cellId}/refresh`;
            const response = await this.instance.get(url);
            return response?.status;
        } catch (error) {
            this.catchError("Equipment", "updateEquipment", error);
        }
    }

    async postRulesProfileImage(cellId: number, productId: number, imageRequestBody: any) {
        try {
            const formData = new FormData();
            formData.append("file", imageRequestBody);
            const headers = {
                "Content-Type": "multipart/form-data",
                ...this.instance.defaults.headers,
            } as any;
            const response = await this.instance.post(
                `product-images/cell/${cellId}/product/image/${productId}`,
                formData,
                {
                    headers: headers,
                }
            );
            Notification.SuccessNotification(
                "Complete",
                "Image uploaded successfully"
            );
            return response?.status;
        } catch (error) {
            this.catchError("Image", "postRulesProfileImage", error);
        }
    }

    async deleteImage(cellId: number, imageId: number) {
        try {
            const cell_id = Number(cellId);
            const id = Number(imageId);
            const url = `product-images/cell/${cell_id}/product/image/id/${id}`;
            const response = await this.instance.delete(url);
            Notification.SuccessNotification(
                "Rules Configuration",
                "Deleted Successfully!"
            );
            return response?.status;
        } catch (error) {
            this.catchError("Rules Configuration", "deleteImage", error);
        }
    }

    async createRule(cellId: number, productId: number, newRuleRequestBody: any) {
        try {
            const cell_id = Number(cellId);
            const product_id = Number(productId);
            const url = `/rule-configs/rules/cell/${cell_id}/product/${product_id}`;
            const response = await this.instance.post(url, newRuleRequestBody);
            return response?.status;
        } catch (error) {
            this.catchError("Rules Configuration", "createRule", error);
        }
    }

    async updateRule(cellId: number, ruleId: number, editRuleRequestBody: any) {
        try {
            const cell_id = Number(cellId);
            const rule_id = Number(ruleId);
            const url = `/rule-configs/rules/cell/${cell_id}/rule/${rule_id}`;
            const response = await this.instance.put(url, editRuleRequestBody);
            return response?.status;
        } catch (error) {
            this.catchError("Rules Configuration", "updateRule", error);
        }
    }

    async updateRecipe(cellId: number, recipeId: number, recipeRequestBody: any) {
        try {
            const cell_id = Number(cellId);
            const recipe_id = Number(recipeId);
            const url = `/rule-configs/rules/cell/${cell_id}/recipe/${recipe_id}`;
            const response = await this.instance.put(url, recipeRequestBody);
            return response?.status;
        } catch (error) {
            this.catchError("Rules Configuration", "updateRecipe", error);
        }
    }

    async deleteRule(cellId: number, ruleId: number) {
        try {
            const cell_id = Number(cellId);
            const rule_id = Number(ruleId);
            const url = `/rule-configs/rules/cell/${cell_id}/rule/${rule_id}`;
            const response = await this.instance.delete(url);
            Notification.SuccessNotification(
                "Rules Configuration",
                "Deleted Successfully!"
            );
            return response?.status;
        } catch (error) {
            this.catchError("Rules Configuration", "deleteRule", error);
        }
    }

    async getStateActionMatrices(cellId: number, productId: number) {
        try {
            const cell_id = Number(cellId);
            const product_id = Number(productId);
            const url = `/state_action_matrix/cell/${cell_id}/product/${product_id}`;
            const response = await this.instance.get(url);
            return response?.data ?? {};
        } catch (error) {
            this.catchError(
                "State Action Matrix Configuration",
                "getStateActionMatrices",
                error
            );
        }
    }

    async getStateActionMatrixHistory(cellId: number, matrixId: number) {
        try {
            const cell_id = Number(cellId);
            const matrix_id = Number(matrixId);
            const url = `/state_action_matrix/cell/${cell_id}/matrix/${matrix_id}/history`;
            const response = await this.instance.get(url);
            return response?.data ?? {};
        } catch (error) {
            this.catchError(
                "State Action Matrix Configuration History",
                "getStateActionMatrixHistory",
                error
            );
        }
    }

    async getStateActionMatrixHistoryByCAPID(cellId: number, cellAssignedPartId: number) {
        try {
            const cell_id = Number(cellId);
            const cell_assigned_part_id = Number(cellAssignedPartId);
            const url = `/state_action_matrix/cell/${cell_id}/cellassignedpartid/${cell_assigned_part_id}/history`;
            const response = await this.instance.get(url);
            return response?.data ?? {};
        } catch (error) {
            this.catchError(
                "State Action Matrix Configuration History by CAPID",
                "getStateActionMatrixHistoryByCAPID",
                error
            );
        }
    }

    async loadMatrix(matrixinstance: any) {
        try {
            const instance = String(matrixinstance).toLowerCase();
            const response = await this.instance(
                `/matrix-controller/loadmatrix/instance/${instance}`
            );
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Matrix Controller", "loadMatrix", error);
        }
    }

    // async getStateActionMatrix(cellId, matrixId) {
    //     try {
    //         const cell_id = Number(cellId);
    //         const matrix_id = Number(matrixId);
    //         const response = await axios.get(`http://localhost:3002/api/v1/customer-instance/state_action_matrix/cell/${cell_id}/matrix/${matrix_id}`);
    //         return response?.data ?? {};
    //     } catch (error) {
    //         this.catchError('State Action Matrix Configuration', 'getStateActionMatrices', error);
    //     }
    // }

    // async getStateActionMatrixHistory(cellId, matrixId) {
    //     try {
    //         const cell_id = Number(cellId);
    //         const matrix_id = Number(matrixId);
    //         const response = await axios.get(`http://localhost:3002/api/v1/customer-instance/state_action_matrix/cell/${cell_id}/matrix/${matrix_id}/history`);
    //         return response?.data ?? {};
    //     } catch (error) {
    //         this.catchError('State Action Matrix Configuration', 'getStateActionMatrixHistory', error);
    //     }
    // }

    // async getStateActionMatrixVersion(cellId, matrixId, version) {
    //     try {
    //         const cell_id = Number(cellId);
    //         const matrix_id = Number(matrixId);
    //         const response = await axios.get(`http://localhost:3002/api/v1/customer-instance/state_action_matrix/cell/${cell_id}/matrix/${matrix_id}/history/${version}`);
    //         return response?.data ?? {};
    //     } catch (error) {
    //         this.catchError('State Action Matrix Configuration', 'getStateActionMatrices', error);
    //     }
    // }

    async createStateActionMatrix(cellId: number, productId: number, requestBody: any) {
        try {
            const cell_id = Number(cellId);
            const product_id = Number(productId);
            const url = `/state_action_matrix/cell/${cell_id}/product/${product_id}`;
            const response = await this.instance.post(url, requestBody);
            return response?.status;
        } catch (error) {
            this.catchError(
                "State Action Matrix Configuration",
                "createStateActionMatrix",
                error
            );
        }
    }

    async createStateActionMatrixHistory(cellId: number, matrixId: number, requestBody: any) {
        try {
            const cell_id = Number(cellId);
            const matrix_id = Number(matrixId);
            const url = `/state_action_matrix/cell/${cell_id}/matrix/${matrix_id}/history`;
            const response = await this.instance.post(url, requestBody);
            return response?.status;
        } catch (error) {
            this.catchError(
                "State Action Matrix Configuration",
                "createStateActionMatrix",
                error
            );
        }
    }

    // async updateStateActionMatrix(cellId, matrixId, requestBody) {
    //     try {
    //         const cell_id = Number(cellId);
    //         const matrix_id = Number(matrixId);
    //         const response = await axios.put(
    //             `http://localhost:3002/api/v1/customer-instance/state_action_matrix/cell/${cell_id}/matrix/${matrix_id}`,
    //             requestBody
    //         );
    //         return response?.status;
    //     } catch (error) {
    //         this.catchError(
    //             "State Action Matrix Configuration",
    //             "updateStateActionMatrix",
    //             error
    //         );
    //     }
    // }

    async updateStateActionMatrix(cellId: number, matrixId: number, requestBody: any) {
        try {
            const cell_id = Number(cellId);
            const matrix_id = Number(matrixId);
            const url = `/state_action_matrix/cell/${cell_id}/matrix/${matrix_id}`;
            const response = await this.instance.put(url, requestBody);
            return response?.status;
        } catch (error) {
            this.catchError(
                "State Action Matrix Configuration",
                "updateStateActionMatrix",
                error
            );
        }
    }

    async getRuns(requestData: any) {
        try {
            const {
                facility_id,
                cell_id,
                product_id,
                run_id,
                start,
                end,
                page_size,
                page_num,
                q,
                sort,
                sort_order,
            } = requestData;
            const queryParams = {
                cell_id,
                product_id,
                run_id,
                start,
                end,
                page_size,
                page_num,
                q,
                sort,
                sort_order,
            };

            const runList = await this.instance.get(`/runs/${facility_id}`, {
                params: queryParams,
            });

            if (runList?.status === 200) {
                return runList?.data ?? {};
            } else {
                Notification.FailedNotification(
                    "Historical Runs",
                    "Failed to fetch Run data"
                );
                return {};
            }
        } catch (ex) {
            this.catchError("Rules Configuration", "getRuns", ex);
        }
    }

    async getCurrentRun(facilityId: number, cellId: number) {
        try {
            const response = await this.instance.get(
                `/runs/current/cell/${cellId}`
            );
            return response?.data;
        } catch (error: any) {
            console.error("Exception in getCurrentRun: ", error);
            Notification.FailedNotification("Runs", error?.message);
            return [];
        }
    }

    async getRun(facilityId: number | string, runId: string) {
        try {
            const response = await this.instance.get(`/runs/facility/${facilityId}/run/${runId}`);
            return response?.data ?? {};
        }
        catch (error) {
            this.catchError("Runs", "getRun", error);
            throw error;
        }
    }


    async getControllersByCell(cellId: number) {
        try {
            const response = await this.instance.get(
                `/rule-configs/controllers/cell/${cellId}`
            );
            return response?.data ?? [];
        } catch (error) {
            this.catchError(
                "Rules Configuration",
                "getControllersByCell",
                error
            );
        }
    }

    /**
     * Retrieves alert logs for a configured alert in a cell
     * @param {Number} cellId
     * @param {string} alertConfigId
     * @returns array of AlertLogResponse objects
     */
    async getAlertConfigLog(cellId: number, alertConfigId: string) {
        try {
            const url = `/alerts/cell/${cellId}/log/config/${alertConfigId}`;
            const response = await this.instance.get(url);
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Alerts Config Log", "getAlertConfigLog", error);
        }
    }

    /**
     * Retrieves all alert logs for a cell
     * @param {*} cellId
     * @returns array of AlertLogResponse objects
     */
    async getAlertsLog(cellId: number) {
        try {
            const url = `/alerts/cell/${cellId}/log`;
            const response = await this.instance.get(url);
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Alerts Log", "getAlertsLog", error);
        }
    }

    /**
     *
     * @param {*} facilityId
     * @param {*} cellId
     * @returns
     */
    async getAlertStatuses(facilityId: number, cellId: number) {
        try {
            const response = await this.instance.get(
                `/process-monitor/facility/${facilityId}/cell/${cellId}/status`
            );
            return response;
        } catch (error) {
            console.error("Exception in getAlertStatuses: ", error);
        }
    }

    async getCriticalTagStats(facilityId: number, cellId: number) {
        try {
            const response = await this.instance.get(
                `/process-monitor/facility/${facilityId}/cell/${cellId}/critical-tags`
            );
            console.log(response)
            return response?.data as CriticalTagStatsResponse;
        } catch (error) {
            console.error("Exception in getAlertStatuses: ", error);
        }
    }

    async updateOEEMetrics(payload: any) {
        try {
            const url = `/cell-oee/updateOEEMetrics`;
            const response = await this.instance.post(url, payload);
            return response?.status;
        } catch (error) {
            this.catchError("Cell OEE Metrics", "updateOEEMetrics", error);
        }
    }

    async getMeasurementSensors(cellId: number, equipmentId: number) {
        try {
            const response = await this.instance.get(
                `/rule-configs/${cellId}/sensors/${equipmentId}`
            );

            return response?.data ?? [];
        } catch (error) {
            this.catchError(
                "Rules Configuration",
                "getMeasurementSensors",
                error
            );
        }
    }

    async getMeasurementEquipment(cellId: number) {
        try {
            const response = await this.instance.get(
                `/rule-configs/outputs/cell/${cellId}/equipment`
            );
            return response?.data ?? [];
        } catch (error) {
            this.catchError("Equipment", "getMeasurementEquipment", error);
        }
    }

    async getCaliperDetails(cellId: number, equipmentDataTagId: number) {
        try {
            const cell_id = Number(cellId);
            const equipmentDataTag_id = Number(equipmentDataTagId);
            const response = await this.instance(
                `/rule-configs/cell/${cell_id}/equipmentdatatagid/${equipmentDataTag_id}`
            );
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Rules Configuration", "getCaliperDetails", error);
        }
    }

    async getEquipmentTemplate(reqObj: any) {
        try {
            const cellId = reqObj?.cell?.id;

            const result = await this.instance.get(`/ui-templates/${cellId}`);

            if (result?.status === 200) {
                const equipmentData = result?.data?.data ?? {};
                return equipmentData;
            } else {
                Notification.FailedNotification(
                    "Signal Data",
                    "Failed to fetch Signal Data Template"
                );
                return {};
            }
        } catch (ex) {
            this.catchError("Signal Data", "getEquipmentTemplate", ex);
        }
    }

    async getControllableEquipByCell(cellId: number) {
        try {
            const cell_id = Number(cellId);
            const response = await this.instance.get(
                `/equipment/controllers/cell/${cell_id}`
            );
            return response?.data ?? {};
        } catch (error) {
            this.catchError(
                "Controllable Equipment",
                "getControllableEquipByCell",
                error
            );
        }
    }

    async getProductListByCell(cellId: number, pageNum: number) {
        try {
            const cell_id = Number(cellId);
            const newUrl = this.handlePagination(
                `/products/cell/${cell_id}`,
                pageNum
            );
            const response = await this.instance.get(newUrl);
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Products", "getProductListByCell", error);
        }
    }

    async getProductDetailsByCellAndProduct(cellId: number, productId: number) {
        try {
            const cell_id = Number(cellId);
            const newUrl = this.handlePagination(
                `/products/cell/${cell_id}/product_id/${productId}`,
                1

            );
            const response = await this.instance.get(newUrl);
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Products", "getProductListByCell", error);
        }
    }

    async getProductsByFacility(facilityId: number, pageNum: number) {
        try {
            const facility_id = Number(facilityId);
            const newUrl = this.handlePagination(
                `/products/facility/${facility_id}`,
                pageNum
            );
            const response = await this.instance.get(newUrl);
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Products", "getProductsByFacility", error);
        }
    }

    async getProducts(productNumber: string) {
        try {
            const response = await this.instance.get(
                `/parts/parts/product-number/${productNumber}`
            );
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Products", "getProducts", error);
        }
    }

    async putProducts(partId: number, productRequestBody: any) {
        try {
            const id = Number(partId);
            const queryParams = { id };
            const url = `/parts/parts/part_id`;
            const response = await this.instance.put(url, productRequestBody, {
                params: queryParams,
            });
            return response?.status;
        } catch (error) {
            this.catchError("Products", "putProducts", error);
        }
    }

    async postProducts(productRequestBody: any) {
        try {
            const url = `/parts/parts`;
            const response = await this.instance.post(url, productRequestBody);
            return response?.status;
        } catch (error) {
            this.catchError("Products", "postProducts", error);
        }
    }

    async postProductFacility(productRequestBody: any) {
        try {
            const url = `/part-facility/part-facilities`;
            const response = await this.instance.post(url, productRequestBody);
            return response?.status;
        } catch (error) {
            this.catchError("Products", "postProductFacility", error);
        }
    }

    async getProductsByERPNumber(erpNumber: string) {
        try {
            const url = `/parts/parts/product-number/${erpNumber}`;
            const response = await this.instance.get(url);
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Products", "getProductsByERPNumber", error);
        }
    }

    async getCellStatusbyFacilityId(facilityId: number) {
        try {
            const url = `/cell-status/cell_status/facility/${facilityId}`;
            const response = await this.instance.get(url);
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Dashboard", "getCellStatusbyFacilityId", error);
        }
    }

    async getMappingByProductsFacility(part_id: number, facility_id: number) {
        try {
            const partId = Number(part_id);
            const facilityId = Number(facility_id);
            const url = `/part-facility/part-facilities/partId/${partId}/facilityId/${facilityId}`;
            const response = await this.instance.get(url);
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Products", "getMappingByProductsFacility", error);
        }
    }

    async putProductionStatusProductsFacility(
        partFacilityId: number,
        productRequestBody: any
    ) {
        try {
            const id = Number(partFacilityId);
            const queryParams = { id };
            const url = `/part-facility/part-facilities/part_facility_id`;
            const response = await this.instance.put(url, productRequestBody, {
                params: queryParams,
            });
            return response?.status;
        } catch (error) {
            this.catchError(
                "Products",
                "putProductionStatusProductsFacility",
                error
            );
        }
    }

    async putFacilityLineAssignedParts(cellId: number, facilityLineRequestBody: any) {
        try {
            const cell_id = Number(cellId);
            const url = `/products-assigned/assigned-parts/cell/${cell_id}/product`;
            const response = await this.instance.put(
                url,
                facilityLineRequestBody
            );
            return response?.status;
        } catch (error) {
            this.catchError("Products", "putFacilityLineAssignedParts", error);
        }
    }

    async putPartAttributeValuesFacilityLine(
        partAttributeValuesFacilityLineRequestBody: any
    ) {
        try {
            const url = `/part-attributes/part-attributes`;
            const response = await this.instance.put(
                url,
                partAttributeValuesFacilityLineRequestBody
            );
            return response?.status;
        } catch (error) {
            this.catchError(
                "Products",
                "putPartAttributeValuesFacilityLine",
                error
            );
        }
    }

    async postPartAttributeValuesFacilityLine(
        partAttributeValuesFacilityLineRequestBody: any
    ) {
        try {
            const url = `/part-attributes/part-attributes`;
            const response = await this.instance.post(
                url,
                partAttributeValuesFacilityLineRequestBody
            );
            return response?.status;
        } catch (error) {
            this.catchError(
                "Products",
                "postPartAttributeValuesFacilityLine",
                error
            );
        }
    }

    async postFacilityLineAssignedParts(
        cell_id: number,
        part_id: number,
        masterFacilityLineAssignedPartsRequestBody: any
    ) {
        try {
            const cellId = Number(cell_id);
            const productId = Number(part_id);
            const url = `/products-assigned/assigned-parts/cell/${cellId}/product/${productId}`;
            const response = await this.instance.post(
                url,
                masterFacilityLineAssignedPartsRequestBody
            );
            return response?.status;
        } catch (error) {
            this.catchError("Products", "postFacilityLineAssignedParts", error);
        }
    }

    async getProductionStatus() {
        try {
            const response = await this.instance.get(
                `/products-status/enum/production-status`
            );
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Production Status", "getProductionStatus", error);
        }
    }

    async getOPCDevices() {
        try {
            const response = await this.instance.get(`/equipment/opc/devices`);
            return response?.data ?? {};
        } catch (error) {
            this.catchError("OPC Devices", "getOPCDevices", error);
        }
    }

    async getOPCConnections(cellId: number) {
        try {
            const cell_id = Number(cellId);
            const response = await this.instance.get(
                `/customer-instance/opc-connections`,
                { params: { cell_id: cell_id } }
            );
            return response?.data ?? {};
        } catch (error) {
            this.catchError("OPC Connections", "getOPCConnections", error);
        }
    }

    async getDestinationTypes() {
        try {
            const response = await this.instance.get(
                `/alerts/destination-types`
            );
            return response?.data ?? [];
        } catch (error) {
            this.catchError("Alerts", "getDestinationTypes", error);
        }
    }

    async getAlertTypes() {
        try {
            const response = await this.instance.get(`/alerts/alert-types`);
            return response?.data ?? [];
        } catch (error) {
            this.catchError("Alerts", "getAlertTypes", error);
        }
    }

    async getNumericAlertTargets(cellId: number, dataTagTypeId: number) {
        try {
            const response = await this.instance.get(
                `/equipment/equipment_tag/cell/${cellId}/numeric?dataTagTypeId=${dataTagTypeId}`
            );
            return response?.data ?? [];
        } catch (error) {
            this.catchError("Alerts", "getAlertTargets", error);
        }
    }

    async getRelatedTargets(cellId: number, equipmentComponentElementId: number) {
        try {
            const response = await this.instance.get(
                `/equipment/equipment_tag/cell/${cellId}/equipment_component_element/${equipmentComponentElementId}`
            );
            return response?.data ?? [];
        } catch (error) {
            this.catchError("Alerts", "getRelatedTargets", error);
        }
    }

    async createAlertConfiguration(cellId: number, alertConfigurationRequestBody: any) {
        try {
            const response = await this.instance.post(
                `/alerts/cell/${cellId}/config`,
                alertConfigurationRequestBody
            );
            return response;
        } catch (error) {
            this.catchError("Alerts", "createAlertConfiguration", error);
        }
    }

    async deleteAlertConfiguration(cellId: number, alertConfigId: string) {
        try {
            const response = await this.instance.delete(
                `/alerts/cell/${cellId}/config/${alertConfigId}`
            );
            return response;
        } catch (error) {
            this.catchError("Alerts", "deleteAlertConfiguration", error);
        }
    }

    async updateAlertConfiguration(
        cellId: number,
        alertConfigId: string,
        alertConfigurationRequestBody: any
    ) {
        try {
            const response = await this.instance.put(
                `/alerts/cell/${cellId}/config/${alertConfigId}`,
                alertConfigurationRequestBody
            );
            return response;
        } catch (error) {
            this.catchError("Alerts", "updateAlertConfiguration", error);
        }
    }

    async subscribeUserToAlert(
        cellId: number,
        alertConfigId: string,
        alertSubscriptionRequestBody: any
    ) {
        try {
            const response = await this.instance.post(
                `/alerts/cell/${cellId}/config/${alertConfigId}/users/subscription`,
                alertSubscriptionRequestBody
            );
            return response;
        } catch (error) {
            this.catchError("Alerts", "subscribeUserToAlerts", error);
        }
    }

    async unsubscribeUserFromAlert(cellId: number, alertConfigId: string, userId: string) {
        try {
            const response = await this.instance.delete(
                `/alerts/cell/${cellId}/config/${alertConfigId}/users/subscriptions/${userId}`
            );
            return response;
        } catch (error) {
            this.catchError("Alerts", "subscribeUserToAlerts", error);
        }
    }

    async getUsersSubscription(cellId: number, alertConfigId: string) {
        try {
            const response = await this.instance.get(
                `/alerts/cell/${cellId}/config/${alertConfigId}/users/subscription`
            );
            return response;
        } catch (error) {
            this.catchError("Alerts", "getUsersSubscription", error);
        }
    }

    async getAlertConfigurations(cellId: number) {
        try {
            const response = await this.instance.get(
                `/alerts/cell/${cellId}/config`
            );
            return response?.data ?? [];
        } catch (error) {
            this.catchError("Alerts", "getAlertConfigurations", error);
        }
    }

    async reloadAlertConfigurations(facilityId: number, cellId: number) {
        try {
            const response = await this.instance.get(
                `/process-monitor/reload/facility/${facilityId}/cell/${cellId}`
            );
            return response;
        } catch (error) {
            this.catchError("Alerts", "reloadAlertConfigurations", error);
        }
    }

    async getInputSignalsData(cellId: number, productId: string, reqBody: any) {
        try {
            const cell_id = Number(cellId);
            const product_id = Number(productId);
            const { page_size, page_num, sort, sort_order } = reqBody;
            const queryParams = { page_size, page_num, sort, sort_order };

            const response = await this.instance.get(
                `/data_science/physics_controllable_input_signals/cell/${cell_id}/product/${product_id}`,
                { params: queryParams }
            );
            return response?.data ?? {};
        } catch (error) {
            this.catchError("ML Configuration", "getInputSignalsData", error);
        }
    }

    async getLiveServices(cellId: number | string, runId: string, queryParams = {}) {
        try {
            const response = await this.instance.get(
                `/dashboards/cell/${cellId}/services/run/${runId}`,
                {
                    params: queryParams,
                }
            );
            return response?.data ?? [];
        } catch (error) {
            this.catchError("Dashboard", "getDashboardUrlList", error);
        }
    }

    async getLiveServicesNoRun(cellId: number, queryParams = {}) {
        try {
            const response = await this.instance.get(
                `/dashboards/cell/${cellId}/services/no-run`,
                {
                    params: queryParams,
                }
            );
            return response?.data ?? [];
        } catch (error) {
            this.catchError("Dashboard", "getDashboardUrlList", error);
        }
    }

    async getDashboardURLList(cellId: number | string, runId: string, queryParams: any) {
        try {
            let response = await this.instance.get(
                `/dashboards/cell/${cellId}/run/${runId}`,
                {
                    params: queryParams,
                }
            );

            // Alert user if no dashboards are found / returned-
            return response?.data?.data ?? [];
        } catch (error) {
            this.catchError("Dashboard", "getDashboardUrlList", error);
        }
    }

    async getNoRunDashboards(cellId: number, queryParams: any) {
        try {
            let response = await this.instance.get(
                `/dashboards/cell/${cellId}/no-run`,
                {
                    params: queryParams,
                }
            );

            // Alert user if no dashboards are found / returned-
            return response?.data?.data ?? [];
        } catch (error) {
            this.catchError("Dashboard", "getDashboardUrlList", error);
        }
    }

    async getOutputSignalsData(cellId: number, productId: number, reqBody: any) {
        try {
            const cell_id = Number(cellId);
            const product_id = Number(productId);
            const { page_size, page_num, sort, sort_order } = reqBody;
            const queryParams = { page_size, page_num, sort, sort_order };

            const response = await this.instance.get(
                `/data_science/physics_output_signals/cell/${cell_id}/product/${product_id}`,
                { params: queryParams }
            );
            return response?.data ?? {};
        } catch (error) {
            this.catchError("ML Configuration", "getOutputSignalsData", error);
        }
    }

    async getDashboardsByRunId(reqObj: any) {
        try {
            const { run_id, cell_id } = reqObj;
            const response = await this.instance.get(
                `/dashboards/cellid/${cell_id}/runid/${run_id}`
            );
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Dashboard", "getDashboardsByRunId", error);
        }
    }

    async getRunReportPDFUrl(cell_id: number, run_id: string) {
        try {
            const response = await this.instance.get(
                `/reporting/cell/${cell_id}/run/${run_id}/summary/pdf`
            );
            return response ?? {};
        } catch (error: any) {
            const errorMessage = { message: error.response.data.detail };
            this.catchError(
                "Historical Runs",
                "getRunReportPDFUrl",
                errorMessage
            );
        }
    }

    async getShapelyChartPNGUrl(cell_id: number, run_id: string) {
        try {
            const response = await this.instance.get(
                `/reporting/cell/${cell_id}/run/${run_id}/shapely/png`
            );
            return response ?? {};
        } catch (error: any) {
            const errorMessage = { message: error.response.data.detail };
            this.catchError(
                "Historical Runs",
                "getShapelyChartPNGUrl",
                errorMessage
            );
        }
    }

    async getDataScienceDPK(cellId: number, runId: number) {
        try {
            const cell_id = Number(cellId);
            const run_id = Number(runId);

            const response = await this.instance.get(
                `/data_science/dpk/cell/${cell_id}/run/${run_id}`
            );
            return response?.data ?? {};
        } catch (error: any) {
            const errorMessage = { message: error.response.data.detail };
            this.catchError(
                "Historical Runs",
                "getDataScienceDPK",
                errorMessage
            );
        }
    }

    // This API return array of objects for Liveline Utilization to populate values for graph
    async getLLUtilizationData(startDate = "", endDate = "", cellId = "") {
        try {
            const queryParams = {
                startDate,
                endDate,
            };
            // Construct the full URL with query string
            const url = `/reporting/cell/${cellId}/shifts/summary`;

            // Append the isLatest query parameter to the endpoint URL
            const response = await this.instance.get(url, {
                params: queryParams,
            });
            return response?.data ?? [];
        } catch (error) {
            this.catchError(
                "Liveline Utilization",
                "getLLUtilizationData",
                error
            );
        }
    }

    async getUtilizationCell(cellId = "", startTime = "", endTime = "") {
        try {
            const queryParams = {
                startTime,
                endTime,
            };
            // Construct the full URL with query string
            const url = `/reporting/utilization/cell/${cellId}`;
            // Append the isLatest query parameter to the endpoint URL
            const response = await this.instance.get(url, {
                params: queryParams,
            });
            return response?.data?.data ?? [];
        } catch (error) {
            this.catchError(
                "Liveline Utilization by Cell",
                "getUtilizationCell",
                error
            );
        }
    }

    async getCellShifts(cellId: number) {
        try {
            const response = await this.instance.get(
                `/oee/cell/${cellId}/shifts`
            );
            return response?.data ?? [];
        } catch (error) {
            this.catchError("Cell Shifts", "getCellShifts", error);
        }
    }

    // This API return a list of all scrap
    async getScrapReasons(cellId: number) {
        try {
            const url = `/oee/recorded_scrap/cell/${cellId}`;

            const response = await this.instance.get(url);
            return response?.data ?? [];
        } catch (error) {
            this.catchError(
                "Cell Timer get scrap reasons",
                "getScrapReasons",
                error
            );
        }
    }

    // region Auth and User Management
    async createUser(createUserBody: Signup) {
        try {
            const url = "/signup";
            const response = await this.instance.post(url, createUserBody);
            return response;
        } catch (error) {
            this.catchError("Admin User", "createUser", error);
        }
    }

    async getRoles() {
        try {
            const response = await this.instance.get<AxiosResponse<Role[]>>(`/roles`);
            return response.data;
        } catch (error) {
            this.catchError("Roles", "getRoles", error);
        }
    }

    async getUsers() {
        try {
            const response = await this.instance.get<AxiosResponse<User[]>>(`/users`);
            return response;
        } catch (error) {
            this.catchError("Users", "getUsers", error);
        }
    }

    async getUserFacilityRoles() {
        try {
            const response = await this.instance.get<AxiosResponse<UserFacilityRole[]>>(`/users/facility-roles`);
            const data = response.data;
            return data;
        }
        catch (error) {
            this.catchError("User Facility Roles", "getUserFacilityRoles", error);
        }
    }


    async getFacilityUsers() {
        try {
            const response = await this.instance.get(`/facility-users`);
            return response;
        } catch (error) {
            this.catchError("FacilityUsers", "getFacilityUsers", error);
        }
    }

    async updateUser(id: string, body: any) {
        try {
            const response = await this.instance.put(`/users/${id}`, body);
            return response;
        } catch (error) {
            this.catchError("Users", "updateUsers", error);
        }
    }

    async addFacilityUser(body: FacilityUserRole) {
        try {
            const response = await this.instance.post(
                `/facility-users`,
                body
            );
            return response;
        } catch (error) {
            this.catchError("FacilityUsers", "updateFacilityUsers", error);
        }
    }

    async updateFacilityUser(facilityUserId: string, body: any) {
        try {
            const response = await this.instance.put(
                `/facility-users/${facilityUserId}`,
                body
            );
            return response;
        } catch (error) {
            this.catchError("FacilityUsers", "updateFacilityUsers", error);
        }
    }

    async removeFacilityUser(facilityUserId: string) {
        try {
            const response = await this.instance.delete(
                `/facility-users/${facilityUserId}`
            );
            return response;
        } catch (error) {
            this.catchError("FacilityUsers", "updateFacilityUsers", error);
        }
    }

    async getFacilityUserByUserId(id: string) {
        try {
            const response = await this.instance.get(
                `/facility-users/user/${id}`
            );
            return response;
        } catch (error) {
            this.catchError("FacilityUser", "getFacilityUser", error);
        }
    }
    // endregion Auth and User Management

    // This API writes an event when a rule is updated-
    async createRuleEvent(cell_id: number, requestBody: any) {
        try {
            const cellId = Number(cell_id);
            const url = `/events/facilityline/cell/${cellId}`;
            await this.instance.post(url, requestBody);
            //return response?.status; // this action is not for the users benefit-
        } catch (error) {
            this.catchError("Rules Configuration", "createRuleEvent", error);
        }
    }

    // This API fetches the last run in all the cells for the selected facility -
    async getLastRun(facility: number) {
        try {
            const response = await this.instance.get(
                `/model-metrics/${facility}`
            );
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Runs", "getLastRun", error);
        }
    }

    // This API fetches the runs for the selected facility and cell -
    async getRunsForCells(facility: number, cell: number) {
        try {
            const response = await this.instance.get(
                `/model-metrics/${facility}/cell/${cell}`
            );
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Runs", "getRunsForCells", error);
        }
    }

    async getExplorerToken() {
        try {
            const response = await this.instance.get(`/explorer/token`);
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Explorer Token", "getExplorerToken", error);
        }
    }

    async getEquipmentData(cellId: number, equipmentDataTagId: number, startDate: string, endDate: string) {
        try {
            const cell_id = Number(cellId);
            const equipmentDataTag_id = Number(equipmentDataTagId);
            const queryParams = { startDate, endDate };
            const response = await this.instance.get(
                `/equipment/equipment_tag/cell/${cell_id}/equipment_data_tag/${equipmentDataTag_id}/data`,
                { params: queryParams }
            );
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Get Equipment Data", "getEquipmentData", error);
        }
    }

    async getCellEquipmentActuals(cellId: number) {
        try {
            const cell_id = Number(cellId);
            const response = await this.instance.get(
                `/equipment/equipment_tag/cell/${cell_id}/actuals`
            );
            return response?.data ?? {};
        } catch (error) {
            this.catchError(
                "Equipment Actuals",
                "getCellEquipmentActuals",
                error
            );
        }
    }

    async getCellExpertSignals(cellId: number, product: number) {
        try {
            const cell_id = Number(cellId);
            let params = undefined;
            if (product) {
                params = { product_id: product };
            }
            const response = await this.instance.get(
                `/equipment/equipment_tag/cell/${cell_id}/expert`,
                { params: params }
            );
            return response?.data ?? {};
        } catch (error) {
            this.catchError("Expert Signals", "getCellExpertSignals", error);
        }
    }

    async getPerformanceURL(facilityId: number) {
        try {
            const response = await this.instance.get(
                `/reporting/utilization/facility/${facilityId}`
            );
            return response?.data ?? {};
        } catch (error: any) {
            const errorMessage = { message: error?.response.data.detail };
            this.catchError(
                "Analytics",
                "getPerformanceURL",
                errorMessage
            );
        }
    }

    async getLPPs(facilityId: number, cellId: number, partId?: number) {
        try {
            const response = await this.instance.get(
                `/lpp/facility/${facilityId}`
            );
            return response?.data ?? {};
        } catch (error) {
            this.catchError("LPPs", "getLPPs", error);
        }
    }

    async getProductionLPPs(facilityId: number, cellId: number, partId: number) {
        try {
            const response = await this.instance.get(
                `/lpp/facility/${facilityId}/cell/${cellId}/part/${partId}`
            );
            return response?.data ?? {};
        } catch (error) {
            this.catchError("LPPs", "getProductionLPPs", error);
        }
    }

}
