import React from 'react';
import { Menu } from 'antd';
import { MenuInfo } from 'rc-menu/lib/interface';
import '../tabs/tabs.scss';
import './menuTabs.scss'

interface MenuTabComponentProps {
    items: any[];
    current: string;
    onClick: (e: MenuInfo) => void;
    mode?: 'horizontal' | 'vertical' | 'inline';
}

const MenuTabComponent = ({ items, current, onClick, mode='horizontal' }: MenuTabComponentProps) => {
    return (
        <div className='tabMenuList'>
            <Menu onClick={onClick} selectedKeys={[current]} mode={mode} items={items}  />
        </div>
    );
};

export default MenuTabComponent;
