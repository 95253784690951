import { createSlice, createAsyncThunk, Dispatch, ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { APIClient } from "../../services/clientAPI";
import { userSelectionActions } from "./userSelectionReducer";

const name = "cellList";

export interface Cell {
    id: number;
    text: string;
    name: string;
    cellNumber: string;
    facility_id: number;
}

export interface CellListState {
    data: Cell[];
    loading: boolean;
}

interface CellFetchParams {
    apiUrl: string;
    facilityId: number;
}

function createInitialState() {
    const stored = localStorage.getItem(name) || "[]";
    const data = JSON.parse(stored) || [];
    return {
        data,
        loading: false,
    };
}

const initLivelineCellSelection = (dispatch: Dispatch, selectedCell: any) => {
    dispatch(userSelectionActions.setUserSelection(selectedCell));
}

async function cellFetch({
    apiUrl,
    facilityId,
}: CellFetchParams, thunkAPI: any) {
    if (apiUrl) {
        const client = new APIClient(apiUrl, thunkAPI.dispatch);

        const response = await client.getFacilityCells(facilityId);
        const cells = response?.data?.length ? response?.data.map(({
            cellNumber,
            id,
            name,
            facility,
        }) => ({
            cellNumber,
            id,
            name,
            text: name,
            facility_id: facility.id!,
        })) : [];

        localStorage.setItem(name, JSON.stringify(cells));
        const storedSelection = localStorage.getItem('userSelectionInfo') || "{}";
        const userSelection = JSON.parse(storedSelection);

        if (userSelection) {
            if (cells.length && facilityId !== userSelection.cell?.facility_id) {
                initLivelineCellSelection(thunkAPI.dispatch, { cell: cells[0] });
            }
        }

        return cells;
    }

    return [];
}

const fetchCellsList = createAsyncThunk(
    'cellList/fetch',
    cellFetch
)

export const retrieveCells = async (dispatcher: any) => {
    const storedUser = localStorage.getItem('user') || "{}";
    const user = JSON.parse(storedUser);
    const storedSelection = localStorage.getItem('userSelectionInfo') || "{}";
    const userSelection = JSON.parse(storedSelection);
    if (user && userSelection?.facility) {
        dispatcher.dispatch(fetchCellsList({
            apiUrl: user.apiURL,
            facilityId: userSelection.facility.id,
        }))
    }
}



export const slice = createSlice({
    name,
    initialState: createInitialState(),
    reducers: {
        setCellList: (state, action) => {
            // Update Cell List
            return action.payload;
        },
        logout: (state) => {
            state.data = [];
            localStorage.removeItem(name);
        },
    },
    extraReducers: (builder: ActionReducerMapBuilder<CellListState>) => {
        builder
            .addCase(fetchCellsList.fulfilled, (state, action) => {
                if (action.payload?.length) {

                    return {
                        loading: false,
                        data: action.payload,
                    };
                }
            })
            .addCase(fetchCellsList.pending, (state) => ({
                loading: true,
                data: state.data,
            }))
            .addCase(fetchCellsList.rejected, () => ({
                loading: false,
                data: [],
            }))
    },
});

// Action creator
export const cellListActions = slice.actions;

export const cellListReducer = slice.reducer; 
