import './footer.scss';
import { leftArrow, rightArrow } from '../../assets/dark-theme';

interface FooterProps {
    onToggle: () => void;
    mobileCollapse?: boolean;
}

const Footer = ({ onToggle = () => {}, mobileCollapse = false }: FooterProps) => {
    return (
        <div className='footerContent'>
            <div className='footerExpandCollapse'>
                <img
                    src={mobileCollapse ? rightArrow : leftArrow}
                    alt='Dashboard'
                    className='mobileCollapseLogo mbtn'
                    onClick={onToggle}
                />
            </div>
            <div className='footerText'>Copyright&#169; {new Date().getFullYear()} Liveline Technologies</div>
        </div>
    );
};

export default Footer;
