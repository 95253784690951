import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Dropdown } from 'antd';
import './header.scss';
import UserIcon from '../../assets/dark-theme/icons/user-yellow.png';
import logout from '../../assets/dark-theme/icons/Logout.svg';
import { PageRoutesToModule } from '../../components/shared/modules'; // Breadcrumbs
import { useSelector } from 'react-redux';
import { decodeToken } from '../../utils/tokenUtils';
import { ConfigProviderComponent, antdTheme } from '../../components/shared';
import { documentation } from '../../assets/dark-theme';
import { CustomerServiceOutlined } from '@ant-design/icons';
import { useSharedContext } from '../../components/shared/ContextProvider';


const baseProductImageClass = 'productIcon svg';

const Header = ({ onLogoutClick = () => {}, onToggle }) => {
    const location = useLocation();
    const user = useSelector((x) => x.auth.user || {});
    const { sharedContext, handleContextChange } = useSharedContext();

    // Extract the breadcrumb from the path
    const breadCrumbArr = location?.pathname?.split('/') ?? [];
    
    // Determine if we're on a run details page
    const isRunDetails = breadCrumbArr.includes('runs') && breadCrumbArr.includes('facility');
    
    // Use the appropriate breadcrumb based on the page type
    let breadCrumb, pageTitle, module;
    
    if (isRunDetails) {
        // For run details pages, use 'runs' as the base breadcrumb
        breadCrumb = 'runs';
        
        // Check if we have a specific submenu selected (from sharedContext)
        if (sharedContext?.currentMenu) {
            const subMenuConfig = PageRoutesToModule[sharedContext.currentMenu];
            pageTitle = PageRoutesToModule[breadCrumb].title + (subMenuConfig?.title || '');
        } else {
            pageTitle = PageRoutesToModule[breadCrumb].title;
        }
        
        module = PageRoutesToModule[breadCrumb].module;
    } else {
        // For all other pages, use the last segment of the path
        breadCrumb = breadCrumbArr?.[breadCrumbArr?.length - 1] ?? 'dashboard';
        const moduleConfig = PageRoutesToModule[breadCrumb];
        
        pageTitle = breadCrumb;
        module = 'basic';
        
        if (moduleConfig) {
            // Only include submenu if we're on a run details page
            pageTitle = moduleConfig['title'];
            module = moduleConfig['module'];
        }
    }
    
    // Clear the current menu when navigating away from run details
    useEffect(() => {
        // If we're not on a run details page and there's a current menu set, clear it
        if (!isRunDetails && sharedContext?.currentMenu) {
            handleContextChange({
                ...sharedContext,
                currentMenu: undefined
            });
        }
    }, [location.pathname, isRunDetails, sharedContext, handleContextChange]);
    
    const moduleIcon = `${baseProductImageClass} ${module}`;
    let email = '';

    if (user?.idToken) {
        const userData = decodeToken(user?.idToken);
        if (userData?.email) email = userData.email;
    }

    const items = [
        {
            label: (
                <a href='https://customerdocs.liveline.cloud/index.html' target={'_blank'} rel='noreferrer'>
                    <div className='profileMenuItem'>
                        <img src={documentation} alt='documentation' className='menuIcon' />
                        <span className='menuName'> Documentation</span>
                    </div>
                </a>
            ),
            key: '0',
        },
        {
            label: (
                <a
                    href='https://liveline.atlassian.net/servicedesk/customer/portals'
                    target={'_blank'}
                    rel='noreferrer'
                >
                    <div className='profileMenuItem'>
                        <CustomerServiceOutlined style={{ fontSize: 16 }} className='menuIcon' />
                        <span className='menuName'> Contact Us</span>
                    </div>
                </a>
            ),
            key: '1',
        },
        {
            label: (
                <div className='profileMenuItem' onClick={onLogoutClick}>
                    <img src={logout} className='menuIcon' title='logout' alt='user icon' />
                    <span className='menuName'>Logout</span>
                </div>
            ),
            key: '2',
        },
    ];

    return (
        <div className='headerContainer'>
            <div className='headerTitle'>
                {moduleIcon && (
                    <span className={moduleIcon} title={module} onClick={onToggle}>
                        &nbsp;&nbsp;
                    </span>
                )}

                <span className='currentPage'>{`${pageTitle}`}</span>
            </div>
            <div className='userName profileUserName'>{email}</div>
            <div className='headerUserProfile'>
                <div className='userIcon'>
                    <ConfigProviderComponent theme={antdTheme}>
                        <Dropdown
                            menu={{
                                items,
                            }}
                            trigger={['click']}
                            placement='bottomRight'
                            arrow
                        >
                            <img src={UserIcon} className='userImg' title={email} alt='user icon' />
                        </Dropdown>
                    </ConfigProviderComponent>
                </div>
            </div>
        </div>
    );
};

export default Header;
