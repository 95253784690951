import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { createListenerMiddleware } from '@reduxjs/toolkit';

import reducers from './features/reducers';
import { fetchFacilitiesList } from './features/facilityListReducer';
import { retrieveCells } from './features/cellListReducer';

const dispatchFacilities = (dispatcher) => {
    const stored = localStorage.getItem('user') || "{}";
    const user = JSON.parse(stored);

    if (user) {
        dispatcher.dispatch(fetchFacilitiesList(user?.apiURL))
    }
}

export default function configureStore(initialState) {
    const sagaMiddleware = createSagaMiddleware();
    const listenerMiddleware = createListenerMiddleware();

    listenerMiddleware.startListening({
        predicate: (_, state, oldState) => {
            return (!!state.auth?.user !== !!oldState.auth?.user);
        },
        effect: (_, api) => dispatchFacilities(api),
    });

    listenerMiddleware.startListening({
        predicate: (_, state, oldState) => state.userSelection?.facility?.id !== oldState.userSelection?.facility?.id,
        effect: (_, api) => retrieveCells(api),
    });

    const middlewares = [listenerMiddleware.middleware, sagaMiddleware, thunk];

    const composeEnhancers = composeWithDevTools({});
    const store = createStore(reducers, initialState, composeEnhancers(applyMiddleware(...middlewares)));

    // get facilities asap
    
    const facilityList = JSON.parse(localStorage.getItem('facilityList'));

    if (!facilityList || !facilityList.length) {
        dispatchFacilities(store);
    }

    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('./features', () => {
            /* eslint-disable-next-line */
            const reducers = require('./features/reducers').default;
            store.replaceReducer(reducers);
        });
    }
    return store;
}
