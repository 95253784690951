import { Location, NavigateFunction, To } from 'react-router-dom';

interface History {
    navigate: NavigateFunction;
    location: Location;
}

export const history: History = {
    navigate: (() => {}) as NavigateFunction,
    location: {
        pathname: '',
        search: '',
        hash: '',
        state: null,
        key: ''
    }
};
