import { createAsyncThunk, createSlice, ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";

export interface LivelineFacility {
    id?: number;
    facilityId?: any;
    livelineId?: any;
}
export interface UserCell {
    id?: number;
    facility_id?: number;
    name?: string;
    cellId?: any;
    cellNumber?: number | string;
}

export interface UserContextSelection {
    facility?: LivelineFacility;
    cell?: UserCell;
    product?: any;
    loading?: boolean;
    error?: string | null;
}
const name = "userSelectionInfo";
function createInitialState() : UserContextSelection {
    const stored = localStorage.getItem(name) || "{}";
    const selection = JSON.parse(stored);
    return {
        facility: selection?.facility || {},
        cell: selection?.cell || {},
        product: selection?.product || {},
        error: null,
        loading: false,
    };
}

function makeLivelineFacility(payload: UserContextSelection) {
    if (payload?.facility && !payload.facility.livelineId) {
        const storedFacilityList = localStorage.getItem("facilityList") || "[]";
        const facilityList : LivelineFacility[] = JSON.parse(storedFacilityList);
        if (facilityList.length) {
            const facility = facilityList.find(
                (f) => f.id === payload?.facility?.id
            );

            if (facility) {
                return {
                    ...payload.facility,
                    livelineId: facility.facilityId,
                };
            }
        }
    }

    return payload?.facility;
}

function createExtraReducers() {
    return (builder:  ActionReducerMapBuilder<UserContextSelection>) => {
        setUserSelectionInfo();
        function setUserSelectionInfo() {
            const { pending, fulfilled, rejected } =
                extraActions.setUserSelectionInfo;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                    state.loading = true;
                })
                .addCase(fulfilled, (state: UserContextSelection, action: PayloadAction<any>) => {
                    let payload = action.payload;
                    if (payload?.facility)
                        state.facility = makeLivelineFacility(payload);
                    if (payload?.cell) state.cell = payload?.cell;
                    if (payload?.product) state.product = payload?.product;
                    localStorage.setItem(name, JSON.stringify(state));
                })
                .addCase(rejected, (state, action) => {
                    state.error = action.error.message;
                    state.loading = false;
                });
        }
    };
}

function createExtraActions() {
    return {
        setUserSelectionInfo: setUserSelectionInfo(),
    };

    function setUserSelectionInfo() {
        return createAsyncThunk(
            `${name}/setUserSelectionInfo`,
            async (userSelectionInfo) => {
                return userSelectionInfo;
            }
        );
    }
}

const initialState = createInitialState();
const extraReducers = createExtraReducers();
const extraActions = createExtraActions();

const slice = createSlice({
    name,
    initialState,
    extraReducers,
    reducers: {
        setUserSelection: function (state, action) {
            const { payload } = action;

            // Update user selection
            const newState = {
                ...state,
            };

            if (payload?.facility)
                newState.facility = makeLivelineFacility(payload);
            if (payload?.cell) newState.cell = payload?.cell;
            if (payload?.product) newState.product = payload?.product;

            localStorage.setItem(name, JSON.stringify(newState));
            return newState;
        },
        logout : function (state) {
            localStorage.removeItem(name);
            return createInitialState();
        }
    },
});

export const userSelectionActions = { ...slice.actions, ...extraActions };
export const userSelectionReducer = slice.reducer;
