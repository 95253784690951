import React from 'react';
import { Select } from 'antd';
import './select.scss';
import { selectSortFunction } from '../../../utils/commonFunctions';

const { Option } = Select;

interface SelectComponentProps {
    className?: string;
    value?: any;
    style?: React.CSSProperties;
    optionFilterProp?: string;
    showSearch?: boolean;
    filterOption?: (input: string, option: any) => boolean;
    filterSort?: (optionA: any, optionB: any) => number;
    options?: Array<{ [key: string]: any }>;
    disabledOptions?: Array<string>;
    onChangeEvent?: (value: any, option: any) => void;
    onClickEvent?: (event: React.MouseEvent<HTMLElement>) => void;
    loading?: boolean;
    disabled?: boolean;
    showArrow?: boolean;
    mode?:  'multiple' | 'tags'
    status?: 'error' | 'warning';
    statusMsg?: string;
    placeholder?: string;
    allowClear?: boolean;
    key?: string;
    text?: string;
    defaultValue?: any;
}

const SelectComponent = ({
    // https://ant.design/components/select#api
    className = '',
    value = undefined,
    style = {},
    optionFilterProp = 'children',
    showSearch = true,
    filterOption = (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    filterSort = (optionA, optionB) => selectSortFunction(optionA?.children, optionB?.children),
    options = [],
    disabledOptions = [],
    onChangeEvent = () => {},
    onClickEvent = () => {},
    loading = false,
    disabled = false,
    showArrow = true,
    mode = undefined,
    status = undefined,
    statusMsg = '',
    placeholder = '',
    allowClear = false,
    key = 'id',
    text = 'text',
    defaultValue,
}: SelectComponentProps) => {
    return (
        <>
            <Select
                disabled={disabled}
                className={className}
                optionFilterProp={optionFilterProp}
                style={style}
                value={value}
                filterOption={filterOption}
                filterSort={filterSort}
                onChange={(item, option) => onChangeEvent(item, option)}
                onClick={onClickEvent}
                loading={loading}
                showSearch={showSearch}
                mode={mode}
                status={status}
                getPopupContainer={(trigger) => trigger.parentNode}
                placeholder={placeholder}
                allowClear={allowClear}
                defaultValue={defaultValue}
            >
                {options?.length &&
                    options.map((item: any) => {
                        return (
                            <Option
                                disabled={disabledOptions.includes(item['text'])}
                                value={item['text']}
                                key={item['id']}
                                {...item}
                            >
                                {item['text']}
                            </Option>
                        );
                    })}
            </Select>
            {statusMsg && <p className='mandatoryMsg'>{statusMsg}</p>}
        </>
    );
};

export default SelectComponent;
